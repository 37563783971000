@tailwind base;
@tailwind components;
@tailwind utilities;

.prose.prose-sm p,
.prose.prose-sm .public-DraftStyleDefault-block {
  @apply m-0;
}

.prose.prose-sm .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: inherit;
}

.gmnoprint li[role='menuitemcheckbox'] {
  @apply flex space-x-1;
}

/* purgecss start ignore */

/* algolia result highlighting */
.ais-Highlight-highlighted {
  @apply not-italic font-bold bg-yellow-200;
}

/* prevent scrollbar jitter */
html {
  @apply font-inter;
}

#HW_badge_cont,
#HW_badge {
  position: absolute;
  top: -5px;
  right: -5px;
  left: unset;
}

#HW_badge {
  @apply bg-red-600;
}

.loading {
  @apply text-lg;
  @apply font-semibold;

  width: 300px;
  height: 300px;
  position: absolute;
  z-index: 501;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: 150px;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes pulse-indigo {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(79, 70, 229, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(79, 70, 229, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(79, 70, 229, 0);
  }
}

.updates {
  position: absolute !important;
  top: -4px;
  right: -10px;
  left: unset;

  .notification-dot {
    background: rgba(79, 70, 229, 1) !important;
    animation: 2s ease 0s infinite normal none running pulse-indigo !important;
    cursor: pointer;
  }
}

#react-refresh-overlay {
  /** get rid of error overlay **/
  display: none;
}

/* simple form + button styling */
.shadow + button {
  @apply mt-6;
}

.action-preview,
.condition-preview {
  @apply text-sm;
  @apply text-gray-500;

  strong {
    @apply font-medium;
    @apply text-theme-secondary;
  }
}

.not-first .condition-preview {
  box-sizing: border-box;
}

.condition-form {
  @apply p-6;
  @apply mt-2;
  @apply border-gray-100;
  @apply border-2;
  @apply round;
  @apply ml-2;
}

@layer utilities {
  @variants responsive {
    .space-y {
      @apply space-y-6;
    }

    .space-x {
      @apply space-x-6;
    }

    .mx {
      @apply mx-6;
    }

    .mt {
      @apply mt-6;
    }

    .mlt {
      @apply ml-6;
    }

    .mr {
      @apply mr-6;
    }

    .p {
      @apply p-6;
    }

    .px {
      @apply px-6;
    }

    .py {
      @apply py-6;
    }

    .pt {
      @apply pt-6;
    }

    .pb {
      @apply pb-6;
    }

    .mb {
      @apply mb-6;
    }

    .pl {
      @apply pl-6;
    }

    .pr {
      @apply pr-6;
    }
  }

  @variants hover, focus {
    .border-gray {
      @apply border-gray-200;
    }

    .bg-gray-50 {
      @apply text-gray-500;
    }

    .blur {
      filter: blur(4px);
    }

    .lighten {
      filter: brightness(150%);
    }

    .contrast {
      filter: invert(2%);
    }

    .darken {
      filter: brightness(97%);
    }

    .faint {
      opacity: 60%;
    }

    .bg-hover {
      @apply bg-gray-50;
    }

    .bg-content {
      @apply bg-white;
    }

    .bg-accent {
      @apply bg-gray-50;
    }
  }

  .text-quiet {
    @apply text-gray-500;
  }

  .text-content {
    @apply text-gray-700;
  }

  .icon-xs {
    width: 16px;
    height: 16px;

    display: inline-block;
  }

  .icon-sm {
    width: 20px;
    height: 20px;

    display: inline-block;
  }

  .icon {
    width: 24px;
    height: 24px;

    display: inline-block;
  }

  .icon-lg {
    width: 28px;
    height: 28px;

    display: inline-block;
  }

  .bg-content .text-secondary {
    @apply text-gray-600;
  }

  .text-content-secondary {
    @apply text-sm;
    @apply text-quiet;
  }

  .bg-disabled {
    @apply bg-gray-100;
  }

  .bg-highlight {
    @apply bg-yellow-200;
  }

  .round {
    @apply rounded-lg;
    border-collapse: separate;
    border-spacing: 0;
  }

  .round-l {
    @apply rounded-l-lg;
  }

  .round-r {
    @apply rounded-r-lg;
  }

  .divide-content {
    @apply divide-gray-200;
  }

  .message + .formgroup {
    @apply mt-6;
  }
}
/* purgecss end ignore */
